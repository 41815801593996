import React  from "react"
import Layout from "../components/Layout"
import Post from "../components/CoursePost"
import { PaddingContainer } from "../elements/shared/containers"
import "../styles/post.css"
import { UseGlobalStateContext } from "../context/globalContext"
import { navigate } from "gatsby"
import { IsBrowser } from "../helpers/utils"
import { PricingPlans } from "../utils/constants"
import StorageService from "../helpers/StorageService"
import SEO from "../components/seo"


const Course = ({ data }) => {
  const userData = StorageService.getService().getUser()

  // if (!userData) {
  //   if (IsBrowser) {
  //     navigate("/auth")
  //     return null
  //   }
  // }

  const description = data.contentfulCourse.excerpt
  const meta = data.contentfulCourse.tags.map(item => item.title)
  const author =  "John"//data.contentfulCourse.author.name
  const url = `/article/${data.contentfulCourse.slug}`
  const image = data.contentfulCourse.image
  const title = data.contentfulCourse.title

  return (
    <Layout hideFooter={true}>

      <SEO description={description}
           meta={meta}
           title={title}
           author={author}
           url={url}
           image={image}
      />

        <Post data={data} />

    </Layout>
  )
}

export const query = graphql`
  query CourseQuery($slug: String!) {
    contentfulCourse(slug: { eq: $slug }) {
      title
      image
      slug
      plan
      freeLessons
      updatedDate(fromNow: true)
      excerpt
      createdDate(formatString: "dddd, DD MMMM YYYY")
      
      tags {
          title
        }
      resources {
        name
        url
      }
      lessons {
        createdDate(fromNow: true)
        createdAt
        isNewLayout
        previews {
          previews
        }
        slug
        title
        updatedDate
        downloads {
          url
          type
          id
          name
        }
        allChapters
        code {
          childMdx {
            body
          }
        }
        explanation {
          childMdx {
            body
            tableOfContents
          }
        }
        script {
          childMdx {
            body
            tableOfContents(maxDepth: 2)
          }
        }
      }
    }
  }
`

export default Course
