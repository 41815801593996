import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { formatStringToId } from "../helpers/utils.js"
import { LI } from "../elements/shared/lists"
import styled, { css }from "styled-components"
import { BsFileEarmarkZip, BsLink45Deg } from "react-icons/bs";
import tw from "tailwind.macro"
import { A , H4, BoldTextWrapper, GrayText} from "../elements/shared/texts"
import { FaLink } from "react-icons/fa";
import { BiHash } from "react-icons/all";


const Toc = styled.div`
margin: 20px 0;
  ol {
    color: ${props => props.theme.colors.primaryText};
    background-color: ${props => props.theme.colors.deepBackground};
    margin-left: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    list-style-type: decimal;
    list-style-position: inside;
    font-size: medium;
  }

    li {
       margin-bottom: 10px;
       padding: 5px;
       /* border-bottom: 1px solid ${ props => props.theme.colors.border }; */
       
       &:hover {
         background-color: ${ props => props.theme.colors.lightPrimary};
         border-radius: 8px;
         color: ${props => props.theme.colors.primary};
       }
    }

    small{
      margin-left: 20px;
      font-family: ${props => props.theme.fonts.secondary}
    }


    li:last-child {
        border-bottom: none;
    }
`

const Downloads = styled.div`

margin: 20px 0;

ul {
    color: ${props => props.theme.colors.primaryText};
    background-color: ${props => props.theme.colors.deepBackground};
    margin-left: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    list-style-type: none;
    list-style-position: inside;
  font-size: medium;
  }

  small{
    margin-left: 20px;
    font-family: ${props => props.theme.fonts.secondary}
  }

    li {
        ${tw`flex items-center`};
       padding: 5px;
    }

    li:last-child {
        border-bottom: none;
    }
`

const Header = styled(motion.h1)`
    padding: 5px;
    border-radius: 8px;
  font-size: medium;
    ${props => props.isOpen && css`
        background-color: ${ props => props.theme.colors.primary};
        color: ${ props => props.theme.colors.textInverse};
    
    `}

    &:hover {
         background-color: ${ props => props.theme.colors.lightPrimary};
         color: ${props => props.theme.colors.primary};
      }

`

const Accordion = ({ item, selected, selectItem, data }) => {
  const isOpen = item.slug === selected.slug;

  return (
    
    <LI isSelected={isOpen}  >
      <Header
          initial={false}
          onClick={selectItem}
          isOpen={isOpen}
      > 
        { item.title } 
      </Header>

        {isOpen && (
          <motion.section
            key="content"
          >
            <Downloads>
              <BoldTextWrapper>
                <GrayText>
                  <small>Resources</small>
                </GrayText>
              </BoldTextWrapper>
              <ul>
                {
                  item.downloads.map(value => (
                    <li key={value.id}>
                      <A href={value.url} target="_blank" key={value.id} className={"flex items-center"}>

                        {
                          value.type === "zip" ? <BsFileEarmarkZip className="mr-2" /> : <BsLink45Deg className="mr-2" />
                        }
                        {value.name}
                      </A>
                    </li>
                  ))
                }
              </ul>
            </Downloads>

            <Downloads>
              <BoldTextWrapper>
                <GrayText>
                  <small>Chapters</small>
                </GrayText>
              </BoldTextWrapper>

              <ul>
                { selected.explanation && selected.explanation.childMdx.tableOfContents.items.map((item, index) => (
                    <p className="pl-2">
                    <A href={`#${formatStringToId(item.title)}`} className={"flex items-center"}>
                      <strong className="pr-2">{index}. </strong>
                      {item.title}
                    </A>
                  </p>
                  ))
                }
              </ul>

            </Downloads>
          </motion.section>
        )}

    </LI>
  );
};

export default Accordion
